import { getReferralCode, getInvitees } from '../../util/api';

// ================ Action types ================ //

export const FETCH_INVITEES_REQUEST = 'app/ReferralProgramPage/FETCH_INVITEES_REQUEST';
export const FETCH_INVITEES_SUCCESS = 'app/ReferralProgramPage/FETCH_INVITEES_SUCCESS';
export const FETCH_INVITEES_ERROR = 'app/ReferralProgramPage/FETCH_INVITEES_ERROR';

export const FETCH_REFERRAL_CODE_REQUEST = 'app/ReferralProgramPage/FETCH_REFERRAL_CODE_REQUEST';
export const UPDATE_PAGINATION = 'app/ReferralProgramPage/UPDATE_PAGINATION';
export const UPDATE_COUNT = 'app/ReferralProgramPage/UPDATE_COUNT';

// ================ Reducer ================ //

const initialState = {
  invitees: new Array(),
  pagination: { limit: 5, offset: 0 },
  count: 0,
  queryParams: null,
  queryInProgress: false,
  queryInviteesError: null,
  referralCode: null,
};

const referralProgramPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_INVITEES_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        queryInProgress: true,
        queryInviteesError: null,
        invitees: payload,
      };
    case FETCH_INVITEES_SUCCESS:
      return {
        ...state,
        pagination: payload,
        queryInProgress: false,
      };
    case FETCH_INVITEES_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        queryInProgress: false,
        queryInviteesError: payload,
      };
    case FETCH_REFERRAL_CODE_REQUEST:
      return {
        ...state,
        referralCode: payload,
        // queryInProgress: false,
      };
    case UPDATE_PAGINATION:
      return {
        ...state,
        pagination: payload,
      };
    case UPDATE_COUNT:
      return {
        ...state,
        count: payload,
      };
    default:
      return state;
  }
};

export default referralProgramPageReducer;

// ================ Action creators ================ //

export const fetchInviteesRequest = invitees => ({
  type: FETCH_INVITEES_REQUEST,
  payload: invitees,
});

export const fetchInviteesSuccess = invitees => ({
  type: FETCH_INVITEES_SUCCESS,
  payload: invitees,
});

export const fetchInviteesError = error => ({
  type: FETCH_INVITEES_ERROR,
  payload: error,
  error: true,
});

export const fetchReferralCodeRequest = referralCode => ({
  type: FETCH_REFERRAL_CODE_REQUEST,
  payload: referralCode,
});

export const updatePagination = pagination => ({
  type: UPDATE_PAGINATION,
  payload: pagination,
});

export const updateCount = count => ({
  type: UPDATE_COUNT,
  payload: count,
});

// ================ Thunks ================ //

export const fetchInvitees = pagination => (dispatch, getState, sdk) => {
  dispatch(updatePagination(pagination));
  return getInvitees(pagination)
    .then(data => {
      if (data.invitees && data.invitees.length > 0) {
        dispatch(fetchInviteesRequest(data.invitees));
        dispatch(updateCount(data.count));
      }
      return data;
    })
    .catch(error => {
      console.error(error, 'fetch-invitees-failed');
      return error;
    });
};

export const fetchReferralCode = (params = null) => (dispatch, getState, sdk) => {
  return getReferralCode()
    .then(referralCode => {
      if (referralCode) {
        dispatch(fetchReferralCodeRequest(referralCode));
      }
      return referralCode;
    })
    .catch(error => {
      console.error(error, 'fetch-referralCode-failed');
      return error;
    });
};

export const loadData = (params, search, config) => dispatch => {
  return Promise.all([
    dispatch(fetchInvitees(initialState.pagination)),
    dispatch(fetchReferralCode()),
  ]).then(response => {
    return response;
  });
};

// These helpers are calling this template's own server-side routes
// so, they are not directly calling Marketplace API or Integration API.
// You can find these api endpoints from 'server/api/...' directory

import appSettings from '../config/settings';
import { types as sdkTypes, transit } from './sdkLoader';
import Decimal from 'decimal.js';

import axios from 'axios';

export const apiBaseUrl = marketplaceRootURL => {
  const port = process.env.REACT_APP_DEV_API_SERVER_PORT;
  const useDevApiServer = process.env.NODE_ENV === 'development' && !!port;

  // In development, the dev API server is running in a different port
  if (useDevApiServer) {
    return `http://localhost:${port}`;
  }

  // Otherwise, use the given marketplaceRootURL parameter or the same domain and port as the frontend
  return marketplaceRootURL ? marketplaceRootURL.replace(/\/$/, '') : `${window.location.origin}`;
};

// Application type handlers for JS SDK.
//
// NOTE: keep in sync with `typeHandlers` in `server/api-util/sdk.js`
export const typeHandlers = [
  // Use Decimal type instead of SDK's BigDecimal.
  {
    type: sdkTypes.BigDecimal,
    customType: Decimal,
    writer: v => new sdkTypes.BigDecimal(v.toString()),
    reader: v => new Decimal(v.value),
  },
];

const serialize = data => {
  return transit.write(data, { typeHandlers, verbose: appSettings.sdk.transitVerbose });
};

const deserialize = str => {
  return transit.read(str, { typeHandlers });
};

const methods = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
};

// If server/api returns data from SDK, you should set Content-Type to 'application/transit+json'
const request = (path, options = {}) => {
  const url = `${apiBaseUrl()}${path}`;
  const { credentials, headers, body, ...rest } = options;

  // If headers are not set, we assume that the body should be serialized as transit format.
  const shouldSerializeBody =
    (!headers || headers['Content-Type'] === 'application/transit+json') && body;
  const bodyMaybe = shouldSerializeBody ? { body: serialize(body) } : {};

  const fetchOptions = {
    credentials: credentials || 'include',
    // Since server/api mostly talks to Marketplace API using SDK,
    // we default to 'application/transit+json' as content type (as SDK uses transit).
    headers: headers || { 'Content-Type': 'application/transit+json' },
    ...bodyMaybe,
    ...rest,
  };

  return window.fetch(url, fetchOptions).then(res => {
    const contentTypeHeader = res.headers.get('Content-Type');
    const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

    if (res.status >= 400) {
      return res.json().then(data => {
        let e = new Error();
        e = Object.assign(e, data);

        throw e;
      });
    }
    if (contentType === 'application/transit+json') {
      return res.text().then(deserialize);
    } else if (contentType === 'application/json') {
      return res.json();
    }
    return res.text();
  });
};

// Keep the previous parameter order for the post method.
// For now, only POST has own specific function, but you can create more or use request directly.
const post = (path, body, options = {}) => {
  const requestOptions = {
    ...options,
    method: methods.POST,
    body,
  };

  return request(path, requestOptions);
};

const postPreMigration = (path, body) => {
  const url = `${apiBaseUrl()}${path}`;
  const options = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/transit+json',
    },
    body: serialize(body),
  };
  return window.fetch(url, options).then(res => {
    const contentTypeHeader = res.headers.get('Content-Type');
    const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

    if (res.status >= 400) {
      return res.json().then(data => {
        let e = new Error();
        e = Object.assign(e, data);

        throw e;
      });
    }
    if (contentType === 'application/transit+json') {
      return res.text().then(deserialize);
    } else if (contentType === 'application/json') {
      return res.json();
    }
    return res.text();
  });
};

const postUploadFiles = (path, body) => {
  const url = `${apiBaseUrl()}${path}`;
  const options = {
    credentials: 'include',
    headers: {
      'Content-Type': 'application/transit+json',
    },
    body: serialize(body),
  };

  return axios
    .post(url, body, options)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log(error);
      return {};
    });
};

// Fetch transaction line items from the local API endpoint.
//
// See `server/api/transaction-line-items.js` to see what data should
// be sent in the body.
export const transactionLineItems = body => {
  return post('/api/transaction-line-items', body);
};

// Initiate a privileged transaction.
//
// With privileged transitions, the transactions need to be created
// from the backend. This endpoint enables sending the order data to
// the local backend, and passing that to the Marketplace API.
//
// See `server/api/initiate-privileged.js` to see what data should be
// sent in the body.
export const initiatePrivileged = body => {
  return post('/api/initiate-privileged', body);
};

// Transition a transaction with a privileged transition.
//
// This is similar to the `initiatePrivileged` above. It will use the
// backend for the transition. The backend endpoint will add the
// payment line items to the transition params.
//
// See `server/api/transition-privileged.js` to see what data should
// be sent in the body.
export const transitionPrivileged = body => {
  return post('/api/transition-privileged', body);
};

// Create user with identity provider (e.g. Facebook or Google)
//
// If loginWithIdp api call fails and user can't authenticate to Marketplace API with idp
// we will show option to create a new user with idp.
// For that user needs to confirm data fetched from the idp.
// After the confirmation, this endpoint is called to create a new user with confirmed data.
//
// See `server/api/auth/createUserWithIdp.js` to see what data should
// be sent in the body.
export const createUserWithIdp = body => {
  return post('/api/auth/create-user-with-idp', body);
};

export const nylasConnectUser = body => {
  return postPreMigration('/api/nylas/connect-user', body);
};
export const nylasDisconnectUser = body => {
  return postPreMigration('/api/nylas/disconnect-user', body);
};
export const nylasFetchConnectCalendarInfo = () => {
  return postPreMigration('/api/nylas/fetch-connected-calendar-info');
};
export const nylasFindFreeBusyTimeslots = body => {
  return postPreMigration('/api/nylas/free-busy-timeslots', body);
};
// export const nylasListCalendars = body => {
//   return post('/api/nylas/list-calendars', body);
// };
// export const nylasFindCalendar = body => {
//   return post('/api/nylas/find-calendar', body);
// };

export const getReferralCode = () => {
  return postPreMigration('/api/referral-program/get-referral-code', {});
};
export const getReferralPerson = body => {
  return postPreMigration('/api/referral-program/get-referral-person', body);
};
export const addInvitee = body => {
  return postPreMigration('/api/referral-program/add-invitee', body);
};
export const getInvitees = body => {
  return postPreMigration('/api/referral-program/get-invitees', body);
};
export const getRewards = body => {
  return postPreMigration('/api/referral-program/get-rewards', body);
};
export const redeemPromos = body => {
  return postPreMigration('/api/referral-program/redeem-promos', body);
};
export const createVoucherifyCustomer = () => {
  return postPreMigration('/api/referral-program/create-voucherify-customer', {});
};

export const syncUserContactDetails = () => {
  return postPreMigration('/api/user/sync-contact-details', {});
};

export const fetchMessageFiles = body => {
  return postPreMigration('/api/message/fetch-files', body);
};
export const saveMessageFiles = body => {
  return postUploadFiles('/api/message/save-files', body);
};

export const getCustomOffers = body => {
  return postPreMigration('/api/custom-offer/get', body);
};
export const getCustomOfferTrxLineItems = body => {
  return postPreMigration('/api/custom-offer/get-trx-line-items', body);
};
export const createCustomOffer = body => {
  return postPreMigration('/api/custom-offer/create', body);
};
export const withdrawalCustomOffer = body => {
  return postPreMigration('/api/custom-offer/withdrawal', body);
};
export const acceptCustomOffer = body => {
  return postPreMigration('/api/custom-offer/accept', body);
};
export const updateSingleDateCustomOffer = body => {
  return postPreMigration('/api/custom-offer/update-single-date-custom-offer', body);
};

export const getGooglePlaceDetails = place_id => {
  return postUploadFiles('/api/google-reviews', { place_id });
};

export const createSetupIntent = body => {
  return postPreMigration('/api/setup-intent', body);
};
export const getStripeCustomer = body => {
  return postPreMigration('/api/setup-intent/get-stripe-customer', body);
};

export const getPaymentMethodsList = body => {
  return postPreMigration('/api/payment-method/list', body);
};

export const deleteCurrentUser = body => {
  return post('/api/user/delete', body);
};

export const redeemVoucherifyPromo = body => {
  return postPreMigration('/api/voucherify/redeem-promo', body);
};

import React from 'react';
import { bool, string } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LINE_ITEM_COUPON_DISCOUNT, propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';

const { Money } = sdkTypes;

// Validate the assumption that the discount exists and the amount
// is zero or positive.
const isValidDiscount = discountLineItem => {
  return (
    discountLineItem &&
    discountLineItem.lineTotal instanceof Money &&
    discountLineItem.lineTotal.amount <= 0
  );
};

const LineItemDiscountMaybe = props => {
  const { lineItems, isCustomer, marketplaceName, intl } = props;

  const discountLineItem = lineItems.find(
    item => item.code === LINE_ITEM_COUPON_DISCOUNT && !item.reversal
  );

  // If discount is passed it will be shown as a fee already reduces from the total price
  let discountItem = null;

  if (isCustomer && discountLineItem) {
    if (!isValidDiscount(discountLineItem)) {
      // eslint-disable-next-line no-console
      console.error('invalid discount line item:', discountLineItem);
      throw new Error('Discount should be present and the value should be zero or positive');
    }

    const discount = discountLineItem.lineTotal;
    const formattedDiscount = discount ? formatMoney(intl, discount) : null;

    discountItem = (
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id="OrderBreakdown.discount" values={{ marketplaceName }} />
        </span>
        <span className={css.itemValue}>{formattedDiscount}</span>
      </div>
    );
  }

  return discountItem;
};

LineItemDiscountMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  isCustomer: bool.isRequired,
  marketplaceName: string.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemDiscountMaybe;
